/* ViewCamera.css */

.ViewCamera {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.camera {
  width: 100%;
  height: 100%;
}

.capture-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background-color: transparent;
  border: 2px solid grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.capture-button:hover {
  background-color: #f00; /* Red background color on hover */
  border: 2px solid grey; /* White border on hover */
}

.confirm-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0f0; /* Green background color */
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-right: 10px; /* Add some spacing between buttons */
}

.redo-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f00; /* Red background color */
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-left: 10px; /* Add some spacing between buttons */
}
