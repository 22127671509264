.Aedes-view {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Aedes-view > * {
  margin: 3px 0;
}

.header {
  text-align: center;
}

.header p {
  margin: 5px 0;
}

.code {
  padding: 5px;
  color: greenyellow;
  background-color: black;
  border-radius: 5px;
}

.content > img {
  display: none;
  width: 100%;
  max-width: 640px;
  max-height: 1280px;
  border-radius: 10px;
}

.content > video {
  display: none;
  width: 100%;
  max-width: 720px;
  max-height: 500px;
  border-radius: 10px;
}

canvas {
  /* display: none; */
  max-width: 640px;
  max-height: 1280px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  text-decoration: none;
  color: white;
  background-color: black;
  border: 2px solid black;
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  color: black;
  background-color: white;
  border: 2px solid black;
}

.text-center {
  text-align: center;
}

.center-content {
  align-content: center;
  text-align: center;
}

/* yolo.css */

.image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  h3 {
    color: white;
    padding-left: 20px;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px; /* Increased padding for spaciousness */
  max-width: 95%; /* Maximum width as a percentage of viewport */
  max-height: 95%; /* Maximum height as a percentage of viewport */
  outline: none;
  border-radius: 8px;
  overflow: hidden; /* Keep content neat */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Optional: add a subtle shadow */
  width: 90%;
}

.modal-image {
  width: 100%; /* Image takes full width of the modal */
  height: auto; /* Maintain aspect ratio */
  max-height: 90vh; /* Set height to a significant portion of viewport height */
  object-fit: contain; /* Ensure the image scales correctly */
}

@media (max-width: 600px) {
  .modal-content {
    max-width: 95%; /* Smaller max-width on smaller screens */
    max-height: 95%;
  }
}

@media (min-width: 601px) {
  .modal-content {
    max-width: 90%; /* Increased max-width for larger screens */
    max-height: 90%; /* Increased max-height for larger screens */
  }
}

/* Image styling */
.yolo-image {
  width: 100%;
  max-height: 640px;
  object-fit: contain;
}

/* Spinner customization */
.loader-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for smaller screens (mobile view) */
@media (max-width: 768px) {
  .image-container {
    flex-direction: column; /* Stack images vertically on smaller screens */
  }

  .image-wrapper {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .yolo-image {
    max-width: 100%;
    height: auto;
  }
}

.centered-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confidence-slider {
  width: 80%;
  max-width: 400px;
  margin: 20px auto;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}
