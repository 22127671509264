.backListButton {
  padding-bottom: 15px;
}

.modalButtons button {
  margin: 5px;
}

.deleteButton {
  margin-top: 20px;
}

.skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.danger-zone {
  background-color: #ffe6e6;
  border: 2px solid #ff6666;
  padding: 20px;
  border-radius: 8px;
}

.danger-zone h3 {
  color: #ff6666;
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 24px;
}

.action-buttons button {
  margin: 0 5px;
}

.action-buttons button:last-child {
  margin-right: 0;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  max-width: 95%;
  max-height: 95%;
  outline: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 90%;
}

.modal-image {
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: contain;
}

/* Card Container */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Card Styles */
.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

/* Media Queries */
@media (min-width: 1201px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}

.image-header {
  text-align: center;
  margin-bottom: 30px;
}

.image-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.image-author {
  font-size: 1.2rem;
  color: #666;
  font-style: italic;
}

@media (max-width: 600px) {
  .image-title {
    font-size: 2rem;
  }
  
  .image-author {
    font-size: 1rem;
  }
}

